import React from 'react';
import { Link } from 'react-router-dom';
import Menu from './../data/menu.json';

const LeftMenu = () => {
  return (
    <>
      <nav className='navbar navbar-vertical fixed-start navbar-expand-md navbar-light nav-left bg-purple'>
        <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#sidebarCollapse' aria-controls='sidebarCollapse' aria-expanded='true' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>

        <div className='navbar-collapse collapse show'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              {
                Menu.map((item) => {
                  return (
                    <Link className='nav-link' key={item.id} to={item.path}>
                      <i className={`fe fe-${item.icon}`} /> {item.text}
                    </Link>
                  )
                })
              }
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default LeftMenu;