import React from 'react'
import Header from '../components/Header'

const ProgramHub = () => {
  return (
    <>
      <Header name='Program Hub' />
    </>
  )
}

export default ProgramHub