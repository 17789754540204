import { Link } from "react-router-dom";

const NoPage = () => {
  return (
    <>
      <div className="d-flex align-items-center min-vh-100 bg-auth border-top border-top-2 border-primary">
        <div className="container">
          <div className="align-items-center row">
            <div className="offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 col-md-6 col-12">
              <div className="text-center">
                <img
                  className="img-fluid"
                  src="https://dashkit-react.vercel.app/img/illustrations/lost.svg"
                  alt="error page"
                />
              </div>
            </div>
            <div className="order-md-1 my-5 col-xl-4 col-md-5 col-12">
              <div className="text-center">
                <h6 className="text-uppercase text-muted mb-4">404 error</h6>
                <h1 className="display-4 mb-3">There's no page here 😭</h1>
                <p className="text-muted mb-4">
                  Looks like you ended up here by accident?
                </p>
                <Link
                  role="button"
                  tabIndex={0}
                  className="btn btn-primary btn-lg"
                  to="/"
                >
                  Return to your dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default NoPage;