import { Authenticator } from '@aws-amplify/ui-react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const components = {
    Header() {
      return (
        <>
          <h1 className='display-4 text-center'>Sign In</h1>
          <p className='text-muted text-center'>
            Mobility Application Portal
          </p>
        </>
      );
    },
    Footer() {
      return (
        <p className='text-center'>Don't have an account?<Link to='/auth/signup'> Sign Up </Link></p>
      );
    },
  }

  return (
    <div className='container-fluid'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-5 col-lg-6 col-xl-4 px-xl-6 my-5 align-self-center'>
          <Authenticator hideSignUp={true} components={components} className='handlenew'>
            {({ signOut, user }) => navigate('/app/application')}
          </Authenticator>
        </div>
        <div className='col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block'>
          <div className='bg-cover h-100 min-vh-100 mt-n1 me-n3' style={{ backgroundImage: 'url(/img/login-cover.jpg)' }} />
        </div>
      </div>
    </div>
  )
}

export default Login