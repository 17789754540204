import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getUser } from '../graphql/queries';
import { API, Auth } from 'aws-amplify';

const ProtectedRoute = ({ children }) => {

  const [data, seData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    handleAuth()
  }, [])

  async function handleAuth() {
    try {
      let cogntioRes = await Auth.currentAuthenticatedUser();
      let currentUser = await API.graphql({
        query: getUser,
        variables: { id: cogntioRes.attributes.sub },
      });
      if (currentUser.data.getUser) {
        seData(currentUser.data.getUser)
      }
    } catch (error) {
      navigate("/login");
      console.log(error);
    }
  }


  if (!data) {
    return <Navigate to='/login' />;
  }
  return children;
};
export default ProtectedRoute;