import React from 'react'
import Header from '../components/Header'

const EligibilityHub = () => {

  return (
    <>
      <Header name='Eligibility Hub' />
    </>
  )
}

export default EligibilityHub