import React from 'react'
import Header from '../components/Header'

const VitalRecords = () => {
  return (
    <>
      <Header name='Vital Records' />
    </>
  )
}

export default VitalRecords