import React from 'react';
import { Link } from 'react-router-dom';

const TopMenu = () => {
  return (
    <div className='main-content' style={{ marginLeft: '250px' }}>
      <nav
        className='navbar navbar-expand-md navbar-light d-none d-md-flex justify-content-end pe-5'
        id='topbar'
      >
       <div className='dropdown'>
            <a
              href='#'
              className='avatar avatar-sm avatar-online dropdown-toggle'
              role='button'
              data-bs-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <img
                src='/img/user.jpg'
                alt='...'
                className='avatar-img rounded-circle d-block'
              />
            </a>
            <div className='dropdown-menu dropdown-menu-end'>
              <Link to='/app/profile' className='dropdown-item'>
                Account Settings
              </Link>
              <a href='#' className='dropdown-item'>
                Help Center
              </a>
              <Link to='/app/billing' className='dropdown-item'>
                Billing
              </Link>
              <hr className='dropdown-divider' />
              <Link to='/' className='dropdown-item'>
                Logout
              </Link>
            </div>
          </div>
      </nav>
    </div>
  )
}

export default TopMenu