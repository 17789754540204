import React from 'react'
import Header from '../components/Header'

const RewardsHub = () => {
  return (
    <>
      <Header name='Rewards Hub' />
    </>
  )
}

export default RewardsHub