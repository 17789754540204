import { createSlice } from '@reduxjs/toolkit'
import { getUserFromLocalStorage } from '../../utils/localStorage'

const initialState = {
  user: getUserFromLocalStorage(),
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    storeUser: (state, {payload}) => {
      state.user = payload
  },
  },
})

// Action creators are generated for each case reducer function
export const { storeUser } = userSlice.actions

export default userSlice.reducer