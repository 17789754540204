/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://eeffbgc6s5gyrpdav6oylvq2ue.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tpouxcokejcofkntbrczjsalvq",
    "aws_cognito_identity_pool_id": "us-east-1:74bd4bb8-13df-43e2-8d82-41470b36dae3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w8g6k3Qx2",
    "aws_user_pools_web_client_id": "3cklf9p8koqgc7uj2nekm4gei5",
    "oauth": {
        "domain": "map-prod.auth.us-east-1.amazoncognito.com"
    },
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "map-cd75506281054-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
