import React from 'react';
import Header from '../components/Header';

const Profile = () => {

  return (
    <>
      <Header name='Account Settings' />
    </>
  )
}

export default Profile