import React from 'react'
import Header from '../components/Header'

const ResoursesHub = () => {
  return (
    <>
      <Header name='Resourses Hub' />
    </>
  )
}

export default ResoursesHub