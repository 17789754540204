import { Authenticator } from '@aws-amplify/ui-react';
import { API } from 'aws-amplify';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { storeUser } from './../../features/user/userSlice';
import { createUser } from './../../graphql/mutations';
import { addUserToLocalStorage } from './../../utils/localStorage';

const Signup = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // formFields 
  const formFields = {
    // signUp 
    signUp: {
      email: {
        order: 2
      },
      name: {
        order: 1
      },
      password: {
        order: 3
      },
      confirm_password: {
        order: 4
      }
    },
  }

  //  components 
  const components = {
    // SignUp 
    SignUp: {
      Header() {
        return (
          <>
            <h1 className='display-4 text-center mt-5 mb-9'>Sign up</h1>
            <p className='text-muted text-center'>
              Sign up to view Dashboard
            </p>
          </>
        );
      },
    },

    Footer() {
      return <p className='text-center'>Already have an account?  <Link to='/'>Sign In</Link></p>
    },

    // ConfirmSignUp 
    ConfirmSignUp: {
      Header() {
        return <h1 className='display-4 text-center mt-5 mb-9'>Verify Account</h1>
      }
    },

    // ResetPassword 
    ResetPassword: {
      Header() {
        <>
          <h1 className='display-2 text-center mt-5 mb-9'>Reset Password</h1>
          <p className='text-muted text-center'>
            Reset Your OTP for free
          </p>
        </>
      }
    },

  }

  const handleCreateUser = async (uservalues) => {
    const { name, sub } = uservalues;
    console.log(uservalues, 'uservalues');
    try {
      await API.graphql({
        query: createUser,
        variables: {
          input: {
            id: sub,
            name: name,
          },
        },
      })
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <div className='container-fluid'>
      <div className='row justify-content-center'>
        <div className='col-12 col-md-5 col-lg-6 col-xl-4 px-xl-6 mb-5 align-self-center mt-sm-5'>
          <Authenticator formFields={formFields} components={components} initialState='signUp'>
            {({ signOut, user }) => {
              handleCreateUser(user?.attributes)
              dispatch(storeUser(user?.attributes));
              addUserToLocalStorage(user?.attributes);
              return navigate('/app/application')
            }}
          </Authenticator>
        </div>
        <div className='col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block'>
          <div className='bg-cover h-100 min-vh-100 mt-n1 me-n3' style={{ backgroundImage: 'url(/img/signup-cover.jpg)' }} />
        </div>
      </div>
    </div>
  )
}

export default Signup