import React from 'react';
import Header from '../components/Header';

const ApplicationHub = () => {
  return (
    <>
      <Header name='Application Hub' />
    </>
  )
}

export default ApplicationHub