/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Header = ({ title, name, menus, children, className }) => {
	return (
		<nav className='header'>
			<div className={className ? className : 'container-fluid'}>
				<div className='header-body'>
					<Row className='align-items-center'>
						<Col>
							<h6 className='header-pretitle'>{title || 'Mobility Application Portal'}</h6>
							<h1 className='header-title'>{name}</h1>

							{/* {
								menus && <>
									<ul className='nav nav-tabs nav-overflow header-tabs'>
										{
											menus.map((menu, index) => {
												return (
													<li key={index} className='nav-item'>
														<Link to={menu.url} className={`nav-link pb-3 pt-4 ${menu.active ? 'active' : ''}`}>
															{menu.name}
														</Link>
													</li>
												)
											})
										}
									</ul>
								</>
							} */}
						</Col>

						{children && <div className='col-auto text-right'> {children}</div>}
					</Row>
				</div>
			</div>
		</nav>
	);
}

export default Header;