import { Outlet } from 'react-router-dom';
import LeftMenu from '../components/LeftMenu';
import TopMenu from '../components/TopMenu';

const Layout = () => {
  return (
    <>
      <TopMenu />
      <LeftMenu />
      <div className='main-content'>
        <Outlet />
      </div>
    </>
  )
};

export default Layout;