
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './auth/ProtectedRoute';
import Welcome from './auth/Welcome';
import ApplicationHub from './pages/ApplicationHub';
import Billing from './pages/Billing';
import CommunityHub from './pages/CommunityHub';
import EligibilityHub from './pages/EligibilityHub';
import Layout from './pages/Layout';
import MyTasks from './pages/MyTasks';
import NoPage from './pages/NoPage';
import Profile from './pages/Profile';
import ProgramHub from './pages/ProgramHub';
import ResoursesHub from './pages/ResoursesHub';
import RewardsHub from './pages/RewardsHub';
import VitalRecords from './pages/VitalRecords';
import Login from './pages/auth/Login';
import Signup from './pages/auth/Signup';

import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/app' element={<ProtectedRoute> <Layout /> </ProtectedRoute>}>
          <Route path='application' element={<ApplicationHub />} />
          <Route path='eligibility' element={<EligibilityHub />} />
          <Route path='vital-records' element={<VitalRecords />} />
          <Route path='program' element={<ProgramHub />} />
          <Route path='community' element={<CommunityHub />} />
          <Route path='resources' element={<ResoursesHub />} />
          <Route path='tasks' element={<MyTasks />} />
          <Route path='rewards' element={<RewardsHub />} />
          <Route path='profile' element={<Profile />} />
          <Route path='billing' element={<Billing />} />
        </Route>
        <Route path='/' element={<Login />} />
        <Route path='/auth/signup' element={<Signup />} />
        <Route path='welcome' element={<Welcome />} />
        <Route path='*' element={<NoPage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;