import React, { useState } from 'react'
import { AiOutlineGoogle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';


const Welcome = () => {

  // initialData 
  const initialData = { email: '', password: '' };

  // validation schema   
  const schema = Yup.object().shape({
    email: Yup.string().email('Email is invalid.').required('Email is required.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters long.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
      ),
  });

  // handleSubmit 
  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    resetForm({ values: '' })
  };


  return (
    <div className="d-flex align-items-center bg-auth border-top signup-container py-3">
      <div className="container-fluid pt-5">
        <div className="align-items-center justify-content-center row">
          <div className="d-none d-lg-block col-xl-6 col-lg-6 col-md-6 col-12 pt-1">
            <div
              className="bg-cover mt-n1 me-n3 me-3 px-5"
              style={{ backgroundImage: 'url("https://images.pexels.com/photos/3810792/pexels-photo-3810792.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")', height: '85vh' }}
            />
          </div>
          <div className="px-lg-6 my-5 col-xl-6 col-lg-6 col-md-6 col-12">

            <div className="row">
              <div className="col-xl-8 mx-auto">
                <div className="text-center">
                  <div className="avatar avatar-xl dropdown-toggle" id="react-aria-2" aria-expanded="false" role="button">
                    <img className="avatar-img rounded-circle" src="https://dashkit-react.vercel.app/img/avatars/profiles/avatar-1.jpg" alt="..." />
                  </div>
                </div>

                <h1 className="display-4 text-center mb-3">Welcome Back</h1>
                <p className="text-muted text-center mb-5">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                </p>

                <Formik initialValues={initialData} validationSchema={schema} onSubmit={handleSubmit}>
                  {({ values, errors, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label className="form-label">Email*</label>
                        <input
                          placeholder="name@address.com"
                          type="email"
                          className="form-control"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                        />
                      </div>
                      {errors.email && <small className='text-danger mt-2 ms-1 '>{errors.email}</small>}
                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label className="form-label">Password*</label>
                          </div>
                          <div className="col-auto">
                            <a
                              className="small text-muted form-text"
                              href="/password-reset"
                            >
                              Forgot password?
                            </a>
                          </div>
                        </div>
                        <div className="input-group-merge input-group">
                          <input
                            placeholder="Enter your password"
                            type="password"
                            className="form-control"
                            name="password"
                            autoComplete="on"
                            value={values.password}
                            onChange={handleChange}
                          />
                          <span className="input-group-text">
                            <svg
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-eye "
                            >
                              <g>
                                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
                                <circle cx={12} cy={12} r={3} />
                              </g>
                            </svg>
                          </span>
                        </div>
                      </div>
                      {errors.password && <small className='text-danger mt-2 ms-1 '>{errors.password}</small>}
                      <button type="submit" className="w-100 mb-3 btn btn-primary btn-lg">
                        Log In
                      </button>

                      <button type="button" className="w-100 mb-3 btn border-dark btn-lg">
                        <AiOutlineGoogle size={20} />  Sign up with Google
                      </button>


                      <p className="text-center">
                        <small className="text-muted text-center">
                          Don't have an account? <Link to="/sign-up">Sign Up</Link>.
                        </small>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>


  )
}

export default Welcome